import 'isomorphic-fetch';
import { getConfiguration } from '../configuration/configurationLoader';
import { getSGConnect } from '../sgConnect';

type BasicType = string | number | Date | boolean | object;

interface FunctionalLog {
    name: string;
    message: string;
    customParameters?: { [key: string]: unknown };
}

interface TechnicalLog {
    message: string;
    customParameters?: { [key: string]: BasicType };
}

export const logTechnical = async (
    type: 'info' | 'warn' | 'error',
    message: string,
    customParameters?: { [key: string]: any },
) => {
    const technicalLog = {
        message,
        customParameters,
    };
    let isSuccess;
    switch (type) {
        case 'error':
        case 'warn':
            await sendLogs('_error', technicalLog);
            break;
        case 'info':
            await sendLogs('_info', technicalLog);
            break;
    }
};

async function sendLogs(
    type: '_error' | '_functional' | '_info',
    logs: FunctionalLog | TechnicalLog,
): Promise<boolean> {
    let retryCount = 0;
    const jsonBody = JSON.stringify(logs);

    const headers = new Headers();
    headers.append('accept', 'application/json');
    headers.append('content-type', 'application/json');

    const sgConnect = getSGConnect();
    const token = sgConnect?.getAuthorizationHeader();
    if (token) {
        headers.append('authorization', token);
    }

    const { apiSubscriptionUrl } = getConfiguration();
    const url = `${apiSubscriptionUrl}/v1/logs/${type}`;

    do {
        try {
            const response = await fetch(url, {
                headers,
                method: 'POST',
                body: jsonBody,
            });

            if (!response.ok) {
                retryCount = retryCount + 1;
                await new Promise(resolve =>
                    // Retry values:
                    // - first time: 5 seconds
                    // - second time: 20 seconds
                    // - third time: 45 seconds
                    setTimeout(resolve, retryCount * retryCount * 5000),
                );
                continue;
            }
            return response.ok;
        } catch {
            retryCount = retryCount + 1;
            await new Promise(resolve =>
                setTimeout(resolve, retryCount * retryCount * 5000),
            );
        }
    } while (retryCount < 3);

    console.error(
        `Cannot send errors to the server after 3 times - url: ${url}`,
    );
    return false;
}
