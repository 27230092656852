import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import React from 'react';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-mini-footer': any;
            }
        }
    }
}

export const Footer: React.FC = () => {
    return <div className="footer w-100 d-flex border-top bg-lvl1">
        <div className="flex-grow-1">
            <WidgetLazyLoader script={`${getConfiguration().widgetCdnBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`}>
                <sgwt-mini-footer
                    id="sgm-mini-footer"
                    no-border
                    mode="sg-markets"
                    contact-us="{'mail':'sg-markets@sgcib.com'}"
                />
            </WidgetLazyLoader>
        </div>
    </div>;
};
