import React from 'react';
import { getConfiguration } from 'core/configuration/configurationLoader';
import { fetcher } from 'core/http/fetcher';
import { Settings, UpdateSettings } from 'core/models/settings';

const defaultSettings = {
    language: 'english',
} as Settings;

const initialState = {
    isLoading: true,
    settings: defaultSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveSettings: (settings: UpdateSettings): Promise<boolean> =>
        Promise.resolve(true),
};

type SettingsProviderState = Readonly<typeof initialState>;

type SettingsProviderProps = {
    contactId: string;
    children?: React.ReactNode;
};

const context = React.createContext<SettingsProviderState>(initialState);
const { Provider, Consumer } = context;

export type SettingsContextState = SettingsProviderState;

class SettingsProvider extends React.Component<
    SettingsProviderProps,
    SettingsProviderState
> {
    public readonly state: SettingsProviderState = initialState;

    public async componentDidMount() {
        await this.loadSettings();
    }

    public async componentDidUpdate(prev: SettingsProviderProps) {
        const { contactId } = this.props;
        const { contactId: prevContactId } = prev;
        if (contactId !== prevContactId) {
            await this.loadSettings();
        }
    }

    public render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    saveSettings: this.handleSaveSettings,
                }}
            >
                {this.props.children}
            </Provider>
        );
    }

    private handleSaveSettings = async (
        updateSettings: UpdateSettings,
    ): Promise<boolean> => {
        this.setState({ isLoading: true });
        const {
            icId,
            language,
        } = updateSettings;

        try {
            const { apiSubscriptionUrl } = getConfiguration();
            await fetcher<object>(
                `${apiSubscriptionUrl}/v1/settings/${this.state.settings.id}`,
                'PUT',
                {
                    icId,
                    language,
                },
                false,
            );

            this.setState({
                settings: {
                    ...this.state.settings,
                    ...updateSettings,
                },
            });
        } catch (_) {
            return false;
        } finally {
            this.setState({
                isLoading: false,
            });
        }

        return true;
    };

    private loadSettings = async () => {
        this.setState({ isLoading: true });

        try {
            const { apiSubscriptionUrl } = getConfiguration();
            const settings = await fetcher<Settings[]>(
                `${apiSubscriptionUrl}/v1/settings?icIds=${this.props.contactId}`,
                'GET',
            );

            const userSettings: Settings = settings != undefined && settings.length > 0 ? settings[0] : {} as Settings;
            this.setState({ settings: userSettings, isLoading: false });
        } catch (_) {
            this.setState({ settings: defaultSettings, isLoading: false });
        }
    };
}

export {
    SettingsProvider,
    Consumer as SettingsConsumer,
    context as SettingsContext,
};
