import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NotificationsProvider } from './components/contexts/NotificationsContext';
import {
    AuthenticationConsumer,
    AuthenticationProvider,
} from './components/contexts/AuthenticationContext';
import { withErrorBoundary } from './components/errorBoundary/ErrorBoundary';
import { ErrorMessage } from './components/errorBoundary/ErrorMessage';
import { Footer } from './components/layout/Footer';
import { Header } from './components/layout/Header';
import { HelpCenter } from './components/layout/HelpCenter';
import { SplashScreen } from './components/layout/SplashScreen';
import { SgwtConnect } from './components/layout/SgwtConnect';
import { ServiceLoader } from './components/common/Loading';
import {
    CurrentUserProvider,
    CurrentUserConsumer,
} from './components/contexts/CurrentUserContext';
import { NonExistentUser } from './components/common/NonExistentUser/NonExistentUser';
import { SettingsProvider } from './components/contexts/SettingsContext';

const Manage = lazy(() => import('./components/pages/manage/Manage'));
const History = lazy(() => import('./components/pages/history/History'));
const ThirdParties = lazy(() =>
    import('./components/pages/thirdParties/ThirdParties'),
);
const InternalServerError = lazy(() =>
    import('./components/pages/error/InternalServerError'),
);
const UnauthorizedError = lazy(() =>
    import('./components/pages/error/UnauthorizedError'),
);
const NotFound = lazy(() => import('./components/pages/error/NotFound'));
const ActiveSubscriptions = lazy(() =>
    import('./components/pages/activeSubscriptions/ActiveSubscriptions'),
);

const MessageErrorBoundary = withErrorBoundary(ErrorMessage);

const AppRoutes: React.FC = () =>
    <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
        <Routes>
            <Route path="/manage/:nature/:group" element={<Manage />} />
            <Route path="/manage/:nature" element={<Manage />} />
            <Route path="/manage" element={<Manage />} />
            <Route path="/history/:page" element={<History />} />
            <Route path="/history" element={<History />} />
            <Route path="/third-parties-access" element={<ThirdParties />} />
            <Route path="/" element={<ActiveSubscriptions />} />
            <Route path="/error403" element={<UnauthorizedError />} />
            <Route path="/error" element={<InternalServerError />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Suspense>;

const Layout: React.FC = () => {
    return (
        <NotificationsProvider>
            <div className="main-container">
                <div className="container container-xxl mb-5">
                    <Suspense fallback={<ServiceLoader />}>
                        <PageLayoutLoader />
                    </Suspense>
                </div>
            </div>
            <Footer />
            <HelpCenter />
            <SgwtConnect />
            <SplashScreen />
        </NotificationsProvider>
    );
};

const PageLayoutLoader: React.FC = () => (
    <AuthenticationConsumer>
        {({ isLoading: isAuthenticationLoading }) => (
            <CurrentUserConsumer>
                {({ isLoading: isCurrentUserLoading }) =>
                    isAuthenticationLoading ||
                    (isCurrentUserLoading && <ServiceLoader />) || <PageLayout />
                }
            </CurrentUserConsumer>
        )}
    </AuthenticationConsumer>
);

export const PageLayout: React.FC = () => (
    <CurrentUserConsumer>
        {({ currentUser }) =>
            (!currentUser && <NonExistentUser />) || (
                <>
                    <AppRoutes />
                </>
            )
        }
    </CurrentUserConsumer>
);

const App: React.FC = () => (
    <MessageErrorBoundary>
        <Router>
            <AuthenticationProvider>
                <AuthenticationConsumer>
                    {authenticationContext =>
                        authenticationContext.authenticatedUser && (
                            <>
                                <Header />
                                <CurrentUserProvider>
                                    <CurrentUserConsumer>
                                        {({ currentUser }) =>
                                            currentUser &&
                                            currentUser.id && (
                                                <SettingsProvider
                                                    contactId={
                                                        currentUser.id
                                                    }
                                                >
                                                    <Layout />
                                                </SettingsProvider>
                                            )
                                        }
                                    </CurrentUserConsumer>
                                </CurrentUserProvider>
                            </>
                        )
                    }
                </AuthenticationConsumer>
            </AuthenticationProvider>
        </Router>
    </MessageErrorBoundary>
);

export default App;
